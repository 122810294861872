import { Container, Grid, GridItem } from "@chakra-ui/layout";
import CookieConsentBanner from "../components/cookie-consent";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { Hero } from "../components/hero";
import { Features } from "../sections/features";
import { How } from "../sections/how";
import { Pricing } from "../sections/pricing";

const Landing = () => {
  return (
    <Container maxWidth={"unset"} padding={0}>
      <Grid
        gap={0}
        templateAreas={`
                                        "header"
                                        "main"
                                        "how-it-works"
                                        "features"
                                        "pricing"
                                        "footer"
                                    `}
      >
        <GridItem
          minHeight="75"
          width="100%"
          bg="hero.100"
          area="header"
          padding={"0 5%"}
        >
          <Header />
        </GridItem>
        <GridItem
          minHeight="95vh"
          width="100%"
          bg="hero.100"
          area="main"
          padding={"0 5%"}
        >
          <Hero />
        </GridItem>
        <GridItem
          id="how-it-works"
          minHeight="100vh"
          bg="white"
          width="100%"
          padding={"0 5%"}
          area="how-it-works"
        >
          <How />
        </GridItem>
        <GridItem
          id="features"
          minHeight="100vh"
          width="100%"
          bg="black"
          area="features"
          padding={"0 5%"}
        >
          <Features />
        </GridItem>
        <GridItem
          id="pricing"
          minHeight="100vh"
          bg="white"
          width="100%"
          area="pricing"
        >
          <Pricing />
        </GridItem>
        <GridItem minHeight="7vh" width="100%" bg="hero.100" area="footer">
          <Footer />
        </GridItem>
      </Grid>
      <CookieConsentBanner />
    </Container>
  );
};

export default Landing;
