import {
  STEP_1_TITLE,
  STEP_1_WRITE,
  STEP_2_TITLE,
  STEP_2_WRITE,
  STEP_3_TITLE,
  STEP_3_WRITE,
  STEP_4_TITLE,
  STEP_4_WRITE,
  STEP_5_TITLE,
  STEP_5_WRITE,
} from "./user-contants";

import URL from "../assets/icons/icons8-url-48.png";
import METRICS from "../assets/icons/icons8-laptop-metrics-64.png";
import INTERACTION from "../assets/icons/icons8-click-80.png";
import FORM from "../assets/icons/icons8-form-48.png";
import FEEDBACK from "../assets/icons/icons8-feedback-48.png";
import SPLIT from "../assets/icons/icons8-split-48.png";
import DASHBOARD from "../assets/icons/icons8-dashboard-48.png";
import REPORT from "../assets/icons/icons8-report-48.png";

export const HOW_IT_WORKS_STEPS = [
  {
    index: 1,
    title: STEP_1_TITLE,
    sentence: STEP_1_WRITE,
  },
  {
    index: 2,
    title: STEP_2_TITLE,
    sentence: STEP_2_WRITE,
  },
  {
    index: 3,
    title: STEP_3_TITLE,
    sentence: STEP_3_WRITE,
  },
  {
    index: 4,
    title: STEP_4_TITLE,
    sentence: STEP_4_WRITE,
  },
  {
    index: 5,
    title: STEP_5_TITLE,
    sentence: STEP_5_WRITE,
  },
];

export const FEATURES = [
  {
    icon: URL,
    title: "URL Integration",
    query: "URL",
    sentence: "Seamlessly add and integrate URLs",
  },
  {
    icon: METRICS,
    title: "Page Metrics",
    query: "Metrics",
    sentence: "Automatically tracks load, scroll metrics",
  },
  {
    icon: INTERACTION,
    title: "Interaction Metrics",
    query: "Interaction",
    sentence: "Tracks specified elements clicks, impressions.",
  },
  {
    icon: FORM,
    title: "Form Submissions",
    query: "Form",
    sentence: "Automatically tracks form submissions",
  },
  {
    icon: FEEDBACK,
    title: "Feedback Widget",
    query: "Feedback",
    sentence: "Out-of-the-box feedback capturing widget",
  },
  {
    icon: SPLIT,
    title: "A/B testing",
    query: "A/B",
    sentence: "Effortless setup for A/B testing",
  },
  {
    icon: DASHBOARD,
    title: "Analytics Dashboard",
    query: "Dashboard",
    sentence: "One place to view all your metrics.",
  },
  {
    icon: REPORT,
    title: "Campaign Reports",
    query: "Reports",
    sentence: "Automatic report generation",
  },
];

export const PRICING = [
  {
    buttonId: "starterPlanButton",
    title: "Starter Plan",
    price: "$19.99",
    maxCampaigns: 1,
  },
  {
    buttonId: "proPlanButton",
    title: "Pro Plan",
    price: "$49.99",
    isRecommended: true,
    maxCampaigns: 3,
  },
  {
    buttonId: "elitePlanButton",
    title: "Elite Plan",
    price: "$59.99",
    maxCampaigns: 5,
  },
];

export const COOKIE_CONSENT = "mvpTesterCookieConsent";
