import { extendTheme } from "@chakra-ui/theme-utils";
import buttonTheme from "./button-theme";
import headingTheme from "./heading-theme";

const theme = extendTheme({
    colors: {
        hero: {
            100: "#E7EBC5"
        }
    },
    fonts: {
        heading: 'Gothic A1,sans-serif'
    },
    fontSizes: {
        xs: "12px",
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
        "2xl": "24px",
        "3xl": "28px",
        "4xl": "36px",
        "5xl": "48px",
        "6xl": "64px",
        "7xl": "80px",
    },
    components: {
        Button: buttonTheme,
        Heading: headingTheme
    }
});

export default theme;