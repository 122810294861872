import {
  Box,
  Container,
  Flex,
  Heading,
  Highlight,
  Text,
} from "@chakra-ui/layout";
import {
  HERO_MIDDLE,
  SUPPORT_TAG,
  SUPPORT_TAG_SECOND,
  TAG_LINE,
} from "../../constants/user-contants";
import HeroImage from "../../assets/images/hero.webp";
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { goToElement } from "../../utils/utils";
import { Hide, Show } from "@chakra-ui/react";

const Hero = () => {
  return (
    <Container
      flexDirection={"column"}
      maxWidth={"unset"}
      height={"100%"}
      maxHeight={"100%"}
      padding={0}
    >
      <Flex
        height={["80%", "85%", "90%", "90%", "90%"]}
        paddingTop={[null, null, "10%"]}
      >
        <Box flex={4} paddingRight={"25px"}>
          <Flex flexDirection={"column"} justifyContent={"center"}>
            <Heading
              as="h1"
              fontSize={["4xl", "4xl", "6xl", "4xl", "6xl", "6xl"]}
              fontWeight={"bold"}
              variant={"heading"}
            >
              <Highlight
                query="not complex setups"
                styles={{ color: "red.500" }}
              >
                {TAG_LINE}
              </Highlight>
            </Heading>
            <Hide breakpoint="(max-width: 768px)">
              <Text fontSize={"2xl"} marginTop={"20px"}>
                <Highlight
                  query="All-In-One solution"
                  styles={{ fontWeight: "bold" }}
                >
                  {SUPPORT_TAG_SECOND}
                </Highlight>
              </Text>
            </Hide>
            <Show breakpoint="(max-width: 1023px)">
              <Box marginTop={"30px"} marginBottom={"20px"}>
                <Image objectFit={"unset"} src={HeroImage} />
              </Box>
            </Show>
            <Text fontSize={"lg"} marginTop={"10px"}>
              <Highlight
                query="in a box."
                styles={{ px: 1, py: 1, bg: "black", color: "hero.100" }}
              >
                {SUPPORT_TAG}
              </Highlight>
            </Text>
          </Flex>
          <Button
            id="learnMoreButton"
            onClick={goToElement.bind(null, "how-it-works")}
            size={"lg"}
            marginTop={"25px"}
            marginBottom={["20px", null, null, null, null]}
          >
            Learn More
          </Button>
          <Button
            id="getStartedHeroButton"
            onClick={() => {
              window.location.href = "https://app.mvptester.com";
            }}
            size={"lg"}
            marginLeft={"25px"}
            variant={"buttonPrimary"}
            marginTop={"25px"}
            marginBottom={["20px", null, null, null, null]}
          >
            Get Started
          </Button>
          <a
            rel="noreferrer"
            href="https://www.producthunt.com/posts/mvp-tester?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-mvp&#0045;tester"
            target="_blank"
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=436251&theme=light&period=daily"
              alt="MVP&#0032;Tester - Don&#0039;t&#0032;waste&#0032;your&#0032;time&#0032;building&#0032;the&#0032;wrong&#0032;idea | Product Hunt"
              style={{ width: "250px", height: "54px", marginBottom: "30px" }}
              width="250"
              height="54"
            />
          </a>
        </Box>
        <Hide breakpoint="(max-width: 1023px)">
          <Box flex={4}>
            <Image fetchpriority="high" objectFit={"unset"} src={HeroImage} />
          </Box>
        </Hide>
      </Flex>
      <Flex
        height={["20%", "15%", "10%", "10%", "10%"]}
        justifyContent={"center"}
      >
        <Box>
          <Text fontSize={["md", "md", "xl", "xl", "2xl", "3xl"]}>
            <Highlight
              query="No monthly subscriptions."
              styles={{ color: "red.500", fontWeight: "bold" }}
            >
              {HERO_MIDDLE}
            </Highlight>
          </Text>
        </Box>
      </Flex>
    </Container>
  );
};

export default Hero;
