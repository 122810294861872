import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Landing from "./views/landing";
import theme from "./styles/themes";
import TermsAndServices from "./views/terms/terms-and-services";
import Privacy from "./views/privacy/privacy";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/terms" Component={TermsAndServices} />
      <Route path="/privacy" Component={Privacy} />

      {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
      <Route path="*" element={<span>No route exists</span>} />
    </Routes>
  </ChakraProvider>
);
