import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/button";
import { Card, CardBody } from "@chakra-ui/card";
import {
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  Highlight,
  List,
  ListItem,
  Text,
} from "@chakra-ui/layout";
import { Stat, StatHelpText, StatNumber } from "@chakra-ui/stat";
import { PRICING } from "../../constants/app-constants";
import {
  ONE_TIME_PRICING,
  PRICING_SUPPORT,
  PRICING_TITLE,
} from "../../constants/user-contants";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";

export const COMMON_FEATURES = [
  "Goal Setting",
  "Campaign Timelines Setting",
  "Automated Page Load Tracking",
  "Automated Scroll Tracking",
  "Automated Form Submission Tracking",
  "Specified Clicks Tracking",
  "Analytics Dashboard",
  "Automated Campaign Reports",
];

export type LocalizedPrice = {
  actual?: string;
  slashed: string;
};

const getLocalizedPrice = (price: string): LocalizedPrice => {
  if (Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta") {
    // India Pricing.
    if (price === "$19.99") {
      return {
        slashed: "Rs. 1499",
      };
    } else if (price === "$49.99") {
      return {
        actual: "Rs. 4497",
        slashed: "Rs. 3999",
      };
    }
    return {
      actual: "Rs. 7495",
      slashed: "Rs. 4499",
    };
  } else {
    if (price === "$19.99") {
      return {
        slashed: "$19.99",
      };
    } else if (price === "$49.99") {
      return {
        slashed: "$49.99",
        actual: "$59.97",
      };
    }
    return {
      slashed: "$59.99",
      actual: "$99.95",
    };
  }
};

export type SavingsType = {
  unitPrice: string;
  savings: string;
  isSaved: boolean;
};

const getSavings = (price: string): SavingsType => {
  if (Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta") {
    // India Pricing.
    if (price === "$19.99") {
      return {
        unitPrice: "Rs. 1499",
        savings: "Rs. 0",
        isSaved: false,
      };
    } else if (price === "$49.99") {
      return {
        unitPrice: "Rs. 1333",
        savings: "Rs. 498",
        isSaved: true,
      };
    }
    return {
      unitPrice: "Rs. 899",
      savings: "Rs. 3000",
      isSaved: true,
    };
  } else {
    if (price === "$19.99") {
      return {
        unitPrice: "$19.99",
        savings: "$0",
        isSaved: false,
      };
    } else if (price === "$49.99") {
      return {
        unitPrice: "$16.66",
        savings: "$9.99",
        isSaved: true,
      };
    }
    return {
      unitPrice: "$11.99",
      savings: "$40",
      isSaved: true,
    };
  }
};

const getListItem = (
  feature: string | any,
  index: number
): JSX.Element | null => {
  const children = [];
  const key = "listItem-" + index;
  if (typeof feature === "string") {
    children.push(
      <CheckIcon
        key={"simple-" + key}
        marginRight={"10px"}
        color={"green.500"}
      />
    );
    children.push(
      <Text key={"simpleText-" + key} fontWeight={"normal"}>
        {feature}
      </Text>
    );
  } else {
    if (feature.isPremium) {
      children.push(
        <CloseIcon
          key={"premiumIcon-" + key}
          marginRight={"10px"}
          color={"red.500"}
        />
      );
    } else {
      children.push(
        <CheckIcon
          key={"simpleIcon-" + key}
          marginRight={"10px"}
          color={"green.500"}
        />
      );
    }
    children.push(
      <Text key={"boldText-" + key} fontWeight={"bold"}>
        {feature.item}
      </Text>
    );
  }
  return (
    <ListItem
      key={key}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
    >
      {children}
    </ListItem>
  );
};

const Pricing = () => {
  return (
    <Container maxWidth={"unset"} padding={"15vh 0"}>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        padding={"0 25px"}
      >
        <Center>
          <Heading
            textAlign={"center"}
            as="h1"
            fontSize={"5xl"}
            fontWeight={"bold"}
            variant={"heading"}
            marginBottom={"25px"}
          >
            {PRICING_TITLE}
          </Heading>
        </Center>
        {/* <Center>
          <Heading
            textAlign={"center"}
            as="h1"
            fontSize={"5xl"}
            fontWeight={"bold"}
            variant={"heading"}
            marginBottom={"25px"}
          >
            {PRICING_TITLE}
          </Heading>
        </Center>
        <Center>
          <Heading
            as="h6"
            fontSize={"xl"}
            fontWeight={"normal"}
            textAlign="center"
          >
            <Highlight
              query={["no to monthly subscriptions", "one-time pricing"]}
              styles={{ px: "1", py: "1", fontWeight: "bold", bg: "red.100" }}
            >
              {PRICING_SUPPORT}
            </Highlight>
          </Heading>
        </Center> */}
        {/* <Center>
          <Heading
            as="h6"
            fontWeight={"normal"}
            fontSize={"xl"}
            marginTop={"10px"}
            textAlign="center"
          >
            <Highlight
              query="one-time pricing"
              styles={{ px: "1", py: "1", fontWeight: "bold", bg: "green.100" }}
            >
              {ONE_TIME_PRICING}
            </Highlight>
          </Heading>
        </Center> */}
        <TableContainer width="7xl">
          <Table>
            <Thead bg={"hero.100"}>
              <Tr>
                <Th>How many campaigns?</Th>
                <Th isNumeric>Total Price</Th>
                <Th isNumeric>Price per campaign</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {PRICING.map((pricing) => {
                const { buttonId, price, title, maxCampaigns } = pricing;
                return (
                  <Tr>
                    <Td isNumeric fontSize="xxx-large">
                      {maxCampaigns}
                    </Td>
                    <Td isNumeric>
                      <Stat>
                        {Boolean(getLocalizedPrice(price).actual) ? (
                          <StatHelpText
                            color="red.500"
                            textDecoration={"line-through"}
                            fontSize="xx-large"
                          >
                            {getLocalizedPrice(price).actual}
                          </StatHelpText>
                        ) : null}
                        <StatHelpText fontWeight="bold" fontSize={"xx-large"}>
                          {getLocalizedPrice(price).slashed}
                        </StatHelpText>
                      </Stat>
                    </Td>
                    <Td isNumeric>
                      <Stat>
                        <StatHelpText fontSize="large">
                          {getSavings(price).unitPrice} per campaign
                        </StatHelpText>
                        <StatHelpText
                          color={
                            getSavings(price).isSaved ? "green.500" : "red.500"
                          }
                        >
                          <Text>
                            <Highlight
                              query={getSavings(price).savings}
                              styles={{
                                fontWeight: "bold",
                                color: "inherit",
                                fontSize: "large",
                              }}
                            >
                              {`Total Savings: ${getSavings(price).savings}`}
                            </Highlight>
                          </Text>
                        </StatHelpText>
                      </Stat>
                    </Td>
                    <Td>
                      <Button
                        id={buttonId}
                        size="sm"
                        fontSize={"md"}
                        variant={"buttonPrimaryMedium"}
                        onClick={() => {
                          window.location.href = `https://app.mvptester.com?goToPlan=${title}`;
                        }}
                      >
                        Buy {maxCampaigns} for{" "}
                        {getLocalizedPrice(price).slashed}
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        {/* <Flex
          marginTop={"5vh"}
          flexDirection={"row"}
          alignItems="center"
          justifyContent={"center"}
          flexWrap="wrap"
          width={["100%", null, null, null, "57%"]}
        >
          /* <Flex
            flex={1}
            flexDirection="column"
            marginBottom={"5vh"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Heading>Features</Heading>
            <List marginTop={"20px"} spacing={3}>
              {COMMON_FEATURES.map(getListItem)}
            </List>
          </Flex>
        </Flex> */}
      </Flex>
    </Container>
  );
};

export default Pricing;
