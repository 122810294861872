import { COOKIE_CONSENT } from "../constants/app-constants";

export const goToElement = (id: string) => {
  const howItWorksElement = document.getElementById(id) as HTMLElement;
  howItWorksElement.scrollIntoView({
    block: "start",
    behavior: "smooth",
    inline: "start",
  });
};

export const getCookieConsentFromStorage = (): boolean => {
  return getFromStorage(COOKIE_CONSENT) === "true";
};

export const getFromStorage = (key: string): string | null => {
  return window.localStorage.getItem(key);
};

export const putToStorage = (key: string, value: string): void => {
  window.localStorage.setItem(key, value);
};
