import { Card, CardBody } from "@chakra-ui/card";
import { Image } from "@chakra-ui/image";
import { Center, Container, Flex, Heading, Highlight, Text } from "@chakra-ui/layout";
import { FEATURES } from "../../constants/app-constants";
import { FEATURES_SUPPORT, FEATURES_TITLE } from "../../constants/user-contants";

const Features = () => {
    return <Container maxWidth={'unset'} padding={'15vh 0'} color='#E7EBC5'>
        <Flex flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Center>
                <Heading as='h1' fontSize={'5xl'} fontWeight={'bold'} variant={'heading'} marginBottom={'25px'} textAlign='center'>
                    {FEATURES_TITLE}
                </Heading>
            </Center>
            <Center>
                <Heading as='h6' fontSize={'xl'} fontWeight={'normal'} textAlign='center'>
                    <Highlight query='All-In-One' styles={{ px: '1', py: '1', fontWeight: 'bold', color: 'orange.500' }}>
                        {FEATURES_SUPPORT}
                    </Highlight>
                </Heading>
            </Center>
            <Flex marginTop={'15vh'} flexDirection={'row'} alignItems='center' justifyContent={'center'} flexWrap='wrap' width='100%'>
                {FEATURES.map(({ icon, title, query, sentence }) => {
                    return (
                        <Card key={icon} marginBottom={'5vh'} variant={'none'} width={['100%', '50%', '33.33%', null, '25%']} color='#E7EBC5' bg='none'>
                            <CardBody display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                                <Image width={'48px'} src={icon} marginBottom={'10px'} />
                                <Heading size='sm' textTransform='uppercase' marginBottom='10px' color='#E7EBC5' textAlign='center'>
                                    <Highlight query={query} styles={{ color: 'orange.500' }}>{title}</Highlight>
                                </Heading>
                                <Text size={'xs'} textAlign='center'>{sentence}</Text>
                            </CardBody>
                        </Card>
                    )
                })}
            </Flex>
        </Flex>
    </Container>
}

export default Features;