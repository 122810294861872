import { Card, CardBody } from "@chakra-ui/card";
import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Highlight,
  Text,
} from "@chakra-ui/layout";
import { HOW_IT_WORKS_STEPS } from "../../constants/app-constants";
import {
  ALL_IN_ONE,
  HOW_IT_WORKS,
  HOW_IT_WORKS_SUPPORT,
} from "../../constants/user-contants";

const How = () => {
  return (
    <Container maxWidth={"unset"} padding={"15vh 0"}>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Center>
          <Heading
            as="h1"
            fontSize={"5xl"}
            fontWeight={"bold"}
            variant={"heading"}
            marginBottom={"25px"}
          >
            {HOW_IT_WORKS}
          </Heading>
        </Center>
        <Center>
          <Heading
            as="h6"
            fontSize={"xl"}
            fontWeight={"normal"}
            textAlign="center"
          >
            <Highlight
              query="100% Data-Driven"
              styles={{ px: "1", py: "1", fontWeight: "bold", bg: "green.100" }}
            >
              {HOW_IT_WORKS_SUPPORT}
            </Highlight>
          </Heading>
        </Center>
        <Center>
          <Heading
            as="h6"
            fontWeight={"normal"}
            fontSize={"xl"}
            marginTop={"10px"}
            textAlign="center"
          >
            <Highlight
              query="All-In-One"
              styles={{ px: "1", py: "1", fontWeight: "bold", bg: "blue.100" }}
            >
              {ALL_IN_ONE}
            </Highlight>
          </Heading>
        </Center>
        <Box marginTop={"5vh"} width={["100%", "100%", "100%", "75%", "50%"]}>
          <iframe
            width="100%"
            height="500"
            src="https://www.youtube.com/embed/3MoNZAcQeMM?si=Zsh370OccewHApm3"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </Box>
        <Flex
          marginTop={"5vh"}
          flexDirection={"row"}
          alignItems="center"
          justifyContent={"center"}
          flexWrap="wrap"
          width="100%"
        >
          {HOW_IT_WORKS_STEPS.map(({ index, title, sentence }) => {
            return (
              <Card
                key={index}
                marginBottom={"5vh"}
                variant={"none"}
                width={["100%", "50%", "50%", null, "33.33%"]}
              >
                <CardBody
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Heading size="2xl" marginBottom="10px" color={"orange.500"}>
                    {index}
                  </Heading>
                  <Heading
                    size="sm"
                    textTransform="uppercase"
                    marginBottom="10px"
                    color={"#157A6E"}
                    textAlign="center"
                  >
                    {title}
                  </Heading>
                  <Text size={"xs"} textAlign="center">
                    {sentence}
                  </Text>
                </CardBody>
              </Card>
            );
          })}
        </Flex>
      </Flex>
    </Container>
  );
};

export default How;
