import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const heading = defineStyle({
    letterSpacing: '-3px'
});

const headingTheme = defineStyleConfig({
    variants: {
        heading
    }
});

export default headingTheme;