export const TAG_LINE = 'Test your idea with simple steps, not complex setups';
export const SUPPORT_TAG = `
Automated Event Trackers + Goal Setting + A/B experiments + Campaigns + Dashboards + much more ... in a box.`;
export const SUPPORT_TAG_SECOND = `All-In-One solution for all your MVP validation campaigns.`;
export const HERO_MIDDLE = `
No monthly subscriptions. Just one-time payment with free 7 day trial.
`;

// export const ABOUT_TAG_LINT = '100% data-driven results';
export const ABOUT_TAG_LINT = 'Why MVP Tester?';

// Why MVP Tester?

export const WHY_MVP_TESTER_SUPPORT_LINE = `
    MVPTester is a focused offering specially for quick idea validation.
`;

// How it works?

export const HOW_IT_WORKS = 'How it works?';
export const HOW_IT_WORKS_SUPPORT = 'Revolutionary Idea Validation - Simple Setup, Comprehensive Insights, 100% Data-Driven Results.';
export const ALL_IN_ONE = 'All-in-One Solution.';

export const STEP_1_TITLE = 'Seamless Integration';
export const STEP_1_WRITE = 'Add your Product URL, install script, and validate the connection effortlessly.';

export const STEP_2_TITLE = 'Define Success, Set Duration';
export const STEP_2_WRITE = 'Clearly set success metrics, campaign duration, and customize thresholds.';

export const STEP_3_TITLE = 'Pinpoint Success Elements';
export const STEP_3_WRITE = 'Identify target elements for success, like buttons or form submissions.';

export const STEP_4_TITLE = 'Optimize with A/B Tests';
export const STEP_4_WRITE = 'Enhance performance by testing variations on select elements, texts, ...';

export const STEP_5_TITLE = 'Unlock Insights, Measure Success';
export const STEP_5_WRITE = 'Gain comprehensive results and insights to measure campaign success.';

// Features

export const FEATURES_TITLE = 'Features';
export const FEATURES_SUPPORT = 'All-in-One Feature Showcase: Explore MVP Tester\'s Comprehensive Capabilities';

// Pricing

export const PRICING_TITLE = 'Simple Pricing, Powerful Results';
export const PRICING_SUPPORT = 'Say no to monthly subscriptions. Choose the plan that suits your project, and experience hassle-free idea validation.';
export const ONE_TIME_PRICING = 'Embrace the freedom of one-time pricing with MVP Tester';