import {
  Box,
  Heading,
  Text,
  Container,
  Link,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

const Privacy = () => {
  return (
    <Container paddingTop={25} paddingBottom={25}>
      <Heading as="h1" mb="4">
        Privacy Policy
      </Heading>
      <Text>Effective Date: 16th March 2024</Text>

      <Box mb="8" marginTop={8}>
        <Heading as="h2" size="md" mb="2">
          Introduction
        </Heading>
        <Text>
          Welcome to MVP Tester ("MVP Tester", "we", "us", or "our"). This
          Privacy Policy describes how we collect, use, and disclose your
          personal information when you use our website (the "Website") and the
          services we offer (the "Services").
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Information We Collect
        </Heading>
        <Text marginBottom={4}>
          We may collect the following types of information when you use the
          Website and Services:
        </Text>
        <UnorderedList ml="4" pl="4">
          <ListItem>
            <b>Personal Information:</b> This may include your name, email
            address, username, and password (if you create an account), and any
            other information you choose to provide to us.
          </ListItem>
          <ListItem>
            <b>Usage Data:</b> This may include information about how you use
            the Website and Services, such as the pages you visit, the features
            you use, and the content you access.
          </ListItem>
          <ListItem>
            <b>Device Information:</b> This may include information about the
            device you use to access the Website, such as your IP address,
            browser type, operating system, and device identifiers.
          </ListItem>
        </UnorderedList>
        <Text></Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          How We Use Your Information
        </Heading>
        <Box>
          <Text marginBottom={4}>
            We may use your information for the following purposes:
          </Text>
          <UnorderedList ml="4" pl="4">
            <ListItem>To provide and improve the Website and Services</ListItem>
            <ListItem>To create and manage your account</ListItem>
            <ListItem>
              To send you marketing and promotional communications (with your
              consent)
            </ListItem>
            <ListItem>To respond to your inquiries and requests</ListItem>
            <ListItem>To analyze how you use the Website and Services</ListItem>
            <ListItem>To comply with legal and regulatory obligations</ListItem>
          </UnorderedList>
        </Box>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Sharing Your Information
        </Heading>
        <Box pl="4">
          <Text>
            We may share your information with third-party service providers who
            help us operate the Website and Services. These third-party service
            providers are contractually obligated to keep your information
            confidential and to use it only for the purposes we have specified.
            We may also share your information with law enforcement or other
            government agencies if we are required to do so by law.
          </Text>
        </Box>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Data Retention
        </Heading>
        <Text>
          We will retain your information for as long as necessary to fulfill
          the purposes described in this Privacy Policy, unless a longer
          retention period is required or permitted by law.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Security
        </Heading>
        <Text>
          We take reasonable steps to protect your information from unauthorized
          access, disclosure, alteration, or destruction. However, no internet
          transmission or electronic storage is completely secure, so we cannot
          guarantee the security of your information.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Your Choices
        </Heading>
        <Text marginBottom={4}>
          You have certain choices regarding your information:
        </Text>
        <UnorderedList ml="4" pl="4">
          <ListItem>
            You can access and update your account information at any time.
          </ListItem>
          <ListItem>
            You can request that we delete your information by{" "}
            <Link color="teal.500" href="mailto:yourmvptester@gmail.com">
              contacting us
            </Link>
            .
          </ListItem>
        </UnorderedList>
        <Text></Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Children's Privacy
        </Heading>
        <Text>
          The Website and Services are not intended for children under the age
          of 13. We do not knowingly collect personal information from children
          under 13. If you are a parent or guardian and you believe that your
          child has provided us with personal information, please{" "}
          <Link color="teal.500" href="mailto:yourmvptester@gmail.com">
            contact us
          </Link>
          .
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Changes to This Privacy Policy
        </Heading>
        <Text>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on the Website.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Contact Us
        </Heading>
        <Text>
          If you have any questions about this Privacy Policy, please contact us
          by email at{" "}
          <Link color="teal.500" href="mailto:yourmvptester@gmail.com">
            yourmvptester@gmail.com
          </Link>
          .
        </Text>
        <Text>
          The operational address: 9, KB's sai apartments, SVT nagar main road,
          Tiruvallur, 600062, Tamil Nadu, India.
        </Text>
      </Box>
    </Container>
  );
};

export default Privacy;
