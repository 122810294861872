import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

const buttonPrimary = defineStyle({
    background: 'black',
    color: 'white',
    fontWeight: 'normal',
    borderRadius: 0,
    padding: '25px 40px',
    fontSize: '24px'
});

const buttonPrimaryMedium = defineStyle({
    ...buttonPrimary,
    padding: '10px 20px',
    fontSize: '20px'
});

const buttonTheme = defineStyleConfig({
    variants: {
        buttonPrimary,
        buttonPrimaryMedium
    }
});

export default buttonTheme;