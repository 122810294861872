import { Image } from "@chakra-ui/image";
import { Container, Flex, Highlight, Link, Text } from "@chakra-ui/layout";
import Logo from "../../assets/images/logo.png";

const Footer = () => {
  return (
    <Container maxWidth={"unset"} height={"100%"} padding={0}>
      <Flex height="100%" justifyContent={"center"} alignItems={"center"}>
        <Image
          boxSize={"50px"}
          height={"unset"}
          margin={"0"}
          objectFit="contain"
          src={Logo}
          alt="MVP Tester Logo"
        />
        <Text fontSize={"2xl"}>
          <Highlight query="MVP" styles={{ fontWeight: "bold" }}>
            MVP Tester
          </Highlight>
        </Text>
        {/* <Text fontSize={"xs"} marginLeft={2} marginTop={2} color={"inherit"}>
          BETA
        </Text> */}
        <Text marginLeft={"20px"}>&copy; 2024</Text>
        <Link color="teal.500" marginLeft={"20px"} href="/terms">
          Terms of Service
        </Link>
        <Link color="teal.500" marginLeft={"20px"} href="/privacy">
          Privacy Policy
        </Link>
      </Flex>
    </Container>
  );
};

export default Footer;
