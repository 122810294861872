import {
  Slide,
  Flex,
  Button,
  Box,
  Text,
  Link,
  SlideFade,
} from "@chakra-ui/react";
import { useState } from "react";
import { COOKIE_CONSENT } from "../../constants/app-constants";
import { getCookieConsentFromStorage, putToStorage } from "../../utils/utils";

const CookieConsentBanner = () => {
  const [cookieConsentOpen, setCookieConsentOpen] = useState(
    !getCookieConsentFromStorage()
  );
  return cookieConsentOpen ? (
    <Box
      position="fixed"
      bottom={0}
      right={0}
      zIndex={9999}
      padding={1}
      display={"flex"}
    >
      <SlideFade in={cookieConsentOpen} style={{ zIndex: 10 }}>
        <Flex
          flex={1}
          background="#212e3f"
          color="#fff"
          padding={"10px"}
          gap={10}
          alignItems="center"
          justifyContent={"center"}
        >
          <Text>
            MVPTester.com uses{" "}
            <Link
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                color: "inherit",
              }}
              href="https://mvptester.com/privacy"
            >
              cookies
            </Link>
            . By continuing to browse the site, you are agreeing to our use of{" "}
            <Link
              style={{
                textDecoration: "underline",
                fontWeight: "bold",
                color: "inherit",
              }}
              href="https://mvptester.com/privacy"
            >
              cookies
            </Link>
            .
          </Text>
          <Button
            size={"md"}
            width="100px"
            colorScheme="teal"
            onClick={() => {
              putToStorage(COOKIE_CONSENT, "true");
              setCookieConsentOpen(!getCookieConsentFromStorage());
            }}
          >
            I Agree
          </Button>
        </Flex>
      </SlideFade>
    </Box>
  ) : null;
};

export default CookieConsentBanner;
