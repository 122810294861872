import { Box, Heading, Text, Container, Link } from "@chakra-ui/react";

const TermsAndServices = () => {
  return (
    <Container paddingTop={25} paddingBottom={25}>
      <Heading as="h1" mb="4">
        Terms of Service
      </Heading>
      <Text>Effective Date: 16th March 2024</Text>

      <Box mb="8" marginTop={8}>
        <Heading as="h2" size="md" mb="2">
          Introduction
        </Heading>
        <Text>
          Welcome to MVP Tester ("MVP Tester", "we", "us", or "our"). These
          Terms of Service ("Terms") govern your access to and use of the MVP
          Tester website (the "Website") and the services offered by MVP Tester
          (the "Services").
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Acceptance of Terms
        </Heading>
        <Text>
          By accessing or using the Website or Services, you agree to be bound
          by these Terms. If you do not agree to all of the Terms, you are not
          authorized to use the Website or Services.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          User Accounts
        </Heading>
        <Box pl="4">
          <Heading as="h3" size="sm" mb="2">
            1.
          </Heading>
          <Text>
            In order to access certain features of the Services, you may be
            required to create an account ("Account"). You are responsible for
            maintaining the confidentiality of your Account information,
            including your username and password. You are also responsible for
            all activities that occur under your Account.
          </Text>

          <Heading as="h3" size="sm" mb="2" mt="4">
            2.
          </Heading>
          <Text>
            You agree to keep your Account information accurate and up-to-date.
          </Text>

          <Heading as="h3" size="sm" mb="2" mt="4">
            3.
          </Heading>
          <Text>
            You agree not to use another person's Account without their
            permission.
          </Text>

          <Heading as="h3" size="sm" mb="2" mt="4">
            4.
          </Heading>
          <Text>
            You agree to notify us immediately of any unauthorized use of your
            Account or any other security breach.
          </Text>
        </Box>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          User Content
        </Heading>
        <Box pl="4">
          <Heading as="h3" size="sm" mb="2">
            1.
          </Heading>
          <Text>
            You may be permitted to create and upload content ("User Content")
            to the Website. You retain all ownership rights to your User
            Content.
          </Text>

          <Heading as="h3" size="sm" mb="2" mt="4">
            2.
          </Heading>
          <Text>
            By uploading User Content to the Website, you grant MVP Tester a
            non-exclusive, worldwide, royalty-free license to use, reproduce,
            modify, publish, distribute, and display your User Content in
            connection with the Website and Services.
          </Text>

          <Heading as="h3" size="sm" mb="2" mt="4">
            3.
          </Heading>
          <Text>
            You are solely responsible for your User Content and the
            consequences of posting or publishing it.
          </Text>

          <Heading as="h3" size="sm" mb="2" mt="4">
            4.
          </Heading>
          <Text>
            You represent and warrant that you have all necessary rights and
            permissions to upload your User Content to the Website.
          </Text>

          <Heading as="h3" size="sm" mb="2" mt="4">
            5.
          </Heading>
          <Text>
            You agree not to upload User Content that is illegal, infringing,
            defamatory, obscene, or otherwise objectionable.
          </Text>
        </Box>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Fees and Payments
        </Heading>
        <Text>
          MVP Tester offers a validation tool for ideas before building a
          product. Users can pay a one-time fee to access the tool.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Free Trial
        </Heading>
        <Text>
          We may, at our sole discretion, offer a pricing plan with a free trial
          for a limited period of time ("Free Trial"). You may be required to
          enter your billing information in order to sign up for the Free Trial.
          If you do enter your billing information when signing up for the Free
          Trial, you will not be charged by us until the Free Trial has expired.
          On the last day of the Free Trial period, unless you cancelled, you
          will be automatically charged as per the applicable plan fees for the
          type of plan you have selected. At any time and without notice, MVP
          Tester reserves the right to (i) modify the terms and conditions of
          the Free Trial offer, or (ii) cancel such Free Trial offer.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Fee Changes
        </Heading>
        <Text>
          MVP Tester, in its sole discretion and at any time, may modify the
          paymeent fees for the available plans. Any plan fee change will become
          effective at the end of the then-current Billing Cycle. MVP Tester
          will provide you with a reasonable prior notice of any change in plan
          fees to give you an opportunity to terminate your plan before such
          change becomes effective. Your continued use of the Service after the
          plan fee change comes into effect constitutes your agreement to pay
          the modified plan fee amount.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Refunds
        </Heading>
        <Text>
          Certain refund requests for plans may be considered by MVP Tester on a
          case-by-case basis and granted in sole discretion of MVP Tester.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Links To Other Web Sites
        </Heading>
        <Text>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by MVP Tester. MVP Tester has no
          control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You
          further acknowledge and agree that MVP Tester shall not be responsible
          or liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with use of or reliance on
          any such content, goods or services available on or through any such
          web sites or services. We strongly advise you to read the terms and
          conditions and privacy policies of any third-party web sites or
          services that you visit.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Promotions and Contests
        </Heading>
        <Text>
          We may occasionally offer promotions, contests, or sweepstakes
          ("Promotions") on the Website.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Feedback and Suggestions
        </Heading>
        <Text>
          You may provide us with feedback and suggestions regarding the Website
          and Services ("Feedback").
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Intellectual Property
        </Heading>
        <Text>
          The Website and Services contain intellectual property owned by MVP
          Tester, including trademarks, copyrights, and trade secrets.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Termination
        </Heading>
        <Text>
          We may terminate your access to the Website and Services for any
          reason, at any time, without notice.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Disclaimers
        </Heading>
        <Text>
          THE WEBSITE AND SERVICES ARE PROVIDED "AS IS" AND WITHOUT WARRANTY OF
          ANY KIND, EXPRESS OR IMPLIED.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Limitation of Liability
        </Heading>
        <Text>
          IN NO EVENT SHALL MVP TESTER BE LIABLE FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT
          OF OR IN ANY WAY RELATED TO YOUR USE OF THE WEBSITE OR SERVICES.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Governing Law
        </Heading>
        <Text>
          These Terms shall be governed by and construed in accordance with the
          laws of India.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Dispute Resolution
        </Heading>
        <Text>
          Any dispute arising out of or relating to these Terms shall be
          resolved by the courts of India.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Entire Agreement
        </Heading>
        <Text>
          These Terms constitute the entire agreement between you and MVP Tester
          regarding your use of the Website and Services.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Updates to the Terms
        </Heading>
        <Text>
          We may revise these Terms from time to time. The revised Terms will be
          effective immediately upon posting them on the Website. You agree to
          review the Terms regularly to stay informed of any changes.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Entire Agreement
        </Heading>
        <Text>
          These Terms constitute the entire agreement between you and MVP Tester
          regarding your use of the Website and Services.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Severability
        </Heading>
        <Text>
          If any provision of these Terms is held to be invalid or
          unenforceable, such provision shall be struck and the remaining
          provisions shall remain in full force and effect.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Waiver
        </Heading>
        <Text>
          No waiver of any provision of these Terms shall be deemed a further or
          continuing waiver of such provision or any other provision.
        </Text>
      </Box>

      <Box mb="8">
        <Heading as="h2" size="md" mb="2">
          Contact Us
        </Heading>
        <Text>
          If you have any questions about these Terms, please contact us by
          email at{" "}
          <Link color="teal.500" href="mailto:yourmvptester@gmail.com">
            yourmvptester@gmail.com
          </Link>
          .
        </Text>
        <Text>
          The operational address: 9, KB's sai apartments, SVT nagar main road,
          Tiruvallur, 600062, Tamil Nadu, India.
        </Text>
      </Box>
    </Container>
  );
};

export default TermsAndServices;
