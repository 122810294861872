import { Image } from "@chakra-ui/image";
import {
  Container,
  Flex,
  Grid,
  GridItem,
  Highlight,
  Text,
} from "@chakra-ui/layout";
import { Link, Show } from "@chakra-ui/react";
import Logo from "../../assets/images/logo.png";
import { goToElement } from "../../utils/utils";

const Header = () => {
  return (
    <Container maxWidth={"unset"} height={"100%"} padding={0}>
      <Grid height={"100%"} gap={0} templateAreas={`"logo menu login"`}>
        <GridItem height="100%" width="4fr" area="logo">
          <Flex height="100%" alignItems={"center"}>
            <Image
              boxSize={"75px"}
              height={"unset"}
              margin={"0"}
              objectFit="contain"
              src={Logo}
              alt="MVP Tester Logo"
            />
            <Text fontSize={"3xl"}>
              <Highlight query="MVP" styles={{ fontWeight: "bold" }}>
                MVP Tester
              </Highlight>
            </Text>
            {/* <Text
              fontSize={"xs"}
              marginLeft={2}
              marginTop={2}
              color={"inherit"}
            >
              BETA
            </Text> */}
          </Flex>
        </GridItem>
        <GridItem height="100%" width="4fr" area="menu"></GridItem>
        <GridItem height="100%" width="4fr" area="login">
          <Show breakpoint="(min-width: 960px)">
            <Flex
              height="100%"
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={[2, 5, 10]}
            >
              <Text
                fontSize={["md", "md", "md", "xl"]}
                cursor={"pointer"}
                _hover={{
                  textDecoration: "underline",
                }}
                onClick={goToElement.bind(null, "how-it-works")}
              >
                How it works?
              </Text>
              <Text
                fontSize={["md", "md", "md", "xl"]}
                cursor={"pointer"}
                _hover={{
                  textDecoration: "underline",
                }}
                onClick={goToElement.bind(null, "features")}
              >
                Features
              </Text>
              <Text
                fontSize={["md", "md", "md", "xl"]}
                cursor={"pointer"}
                _hover={{
                  textDecoration: "underline",
                }}
                onClick={goToElement.bind(null, "pricing")}
              >
                Pricing
              </Text>
              <Link
                fontSize={["md", "md", "md", "xl"]}
                cursor={"pointer"}
                background="red.500"
                padding="4px 12px"
                borderRadius={"16px"}
                color="white"
                href="https://app.mvptester.com/"
                target={"_blank"}
                _hover={{
                  textDecoration: "none",
                  transform: "scale3d(.93, .93, 1.01)",
                }}
              >
                Dashboard
              </Link>
            </Flex>
          </Show>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default Header;
